@import "../utils/variables-mixins";

$loader-background-color: $black-pearl;
$loader-color: $burnt-sienna;

$loader-size: 2em;
$loader-animation-duration: 1.15s;
$loader-animation-delay: 1.5s;
$spin-duration: 1.5s;

.loader-animation-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-font-smoothing: antialiased;
  background-color: $loader-background-color;
  &::before {
    content: "";
    position: aboslute;
    width: 100%;
    height: 100%;
    background: $loader-background-color;
    opacity: 0.25;
  }
  .loader,
  .loader__figure {
    position: absolute;
    transform: translate(-50%, -50%);
  }

  .loader {
    overflow: visible;
    padding-top: $loader-size;
    width: $loader-size;
    height: 0;
  }

  .loader__figure {
    width: 0;
    height: 0;
    box-sizing: border-box;
    border: 0 solid $loader-color;
    border-radius: 50%;
    animation: loader-figure $loader-animation-duration $loader-animation-delay infinite cubic-bezier(0.22, 0.61, 0.36, 1);
  }
}

.loader-animation-login {
  border-radius: 50%;
  width: $loader-size;
  height: $loader-size;
  border: .25rem solid rgba($burnt-sienna, 0.2);
  border-top-color: $burnt-sienna;
  animation: spin $spin-duration infinite linear;
  margin: 0 auto 2em;
}

// .loader-animation-container-login {
//   position: relative;
//   height: 2em;
//   // top: 0;
//   // bottom: 2em;
//   // left: 50%;
//   // right: 42%;
//   // text-align: center;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   -webkit-font-smoothing: antialiased;
//   // background-color: $loader-background-color;
//   // &::before {
//   //   content: "";
//   //   position: aboslute;
//   //   width: 100%;
//   //   height: 100%;
//   //   background: $loader-background-color;
//   //   opacity: 0.25;
//   // }
//   .loader,
//   .loader__figure {
//     position: absolute;
//     transform: translate(-50%, -50%);
//   }

//   .loader {
//     overflow: visible;
//     padding-top: $loader-size;
//     width: $loader-size;
//     height: 0;
//   }

//   .loader__figure {
//     width: 0;
//     height: 0;
//     box-sizing: border-box;
//     border: 0 solid $loader-color;
//     border-radius: 50%;
//     animation: loader-figure $loader-animation-duration $loader-animation-delay infinite cubic-bezier(0.22, 0.61, 0.36, 1);
//   }
// }


// LOADER KEYFRAMES
// -----------------

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-figure {
  0% {
    width: 0;
    height: 0;
    background-color: $loader-color;
  }

  29% {
    background-color: $loader-color;
  }

  30% {
    width: $loader-size;
    height: $loader-size;
    background-color: transparent;
    border-width: $loader-size / 2;
    opacity: 1;
  }

  100% {
    width: $loader-size;
    height: $loader-size;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}
